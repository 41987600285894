import { Urls } from "@/Urls";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import VueRouter, { RouteConfig } from "vue-router";
import Decision from "../views/DecisionView.vue";
import ModelView from "../views/ModelView.vue";
import ModelViewAI from "../views/ModelViewAI.vue";
import ChoiceEditor from "../views/ChoiceEditor.vue";
import WorkspacesView from "../views/WorkspacesView.vue";
import DecisionsView from "../views/DecisionsView.vue";
import AppView from "../views/AppView.vue";
import UnitView from "../views/UnitView.vue";
import CompareView from "../views/CompareView.vue";
import AppManagerView from "../views/AppManagerView.vue";
import RankView from "../views/RankView.vue";
import TabEditorView from "../views/TabEditorView.vue";
import ChoicesView from "../views/ChoicesView.vue";
import ViewpointsView from "../views/ViewpointsView.vue";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";
import UsersView from "../views/UsersView.vue";
import ParentRouteView from "../views/ParentRouteView.vue";
import ParentRouteKeep from "../views/ParentRouteKeep.vue";
import ScoreClassTable from "../views/ScoreClassTable.vue";
import AccountView from "../views/AccountView.vue";
import LoginView from "../views/LoginView.vue";
import LoginForm from "../components/login/LoginForm.vue";
import ResetForm from "../components/login/ResetForm.vue";
import SignupForm from "../components/login/SignupForm.vue";
import InvitationView from "../views/InvitationView.vue";
import AWSLogin from "../components/login/AWSLogin.vue";
import FactorAIView from "../views/FactorAIView.vue";
import Decisions from "@/store/modules/Decisions";

const decisionsModule = getModule(Decisions);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: Urls.HOME,
        name: "Home",
        component: HomeView,
        props: (route) => ({
            queryWId: route.query.w,
            queryFId: route.query.f,
            querySettings: route.query.settings && route.query.settings == "true",
        }),
        children: [
            {
                name: "Model",
                path: Urls.MODEL,
                component: ParentRouteKeep,
                props: () => ({
                    keepAlive: ["ModelViewAI", "FactorAIView"],
                }),
                meta: {
                    title: "Model",
                    requireModel: true,
                    breadCrumb: true,
                    label: () => "Factors",
                },
                children: [
                    {
                        name: "Model",
                        path: `${Urls.MODEL}/`,
                        component: ModelView,
                        props: () => ({
                            view: "ModelView",
                        }),
                        meta: {
                            title: "Model",
                            requireModel: true,
                        },
                    },
                    {
                        name: "ModelSearchView",
                        path: Urls.MODEL_SEARCH,
                        component: ModelView,
                        props: () => ({
                            view: "ModelSearchView",
                        }),
                        meta: {
                            title: "Model Search",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Find Existing Factors",
                        },
                    },
                    {
                        name: "ModelImportFrameworkView",
                        path: Urls.MODEL_IMPORT_FRAMEWORK,
                        component: ModelView,
                        props: () => ({
                            view: "ModelImportFrameworkView",
                        }),
                        meta: {
                            title: "Import Framework",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Import Framework",
                        },
                    },
                    {
                        name: "ModelTableView",
                        path: Urls.MODEL_TABLE,
                        component: ModelView,
                        props: () => ({
                            view: "ModelTableView",
                        }),
                        meta: {
                            title: "Model Table Columns",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Extract form Table",
                        },
                    },
                    {
                        name: "ModelViewAI",
                        path: Urls.MODEL_AI,
                        component: FactorAIView,
                        meta: {
                            title: "Model AI Suggestions",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "AI Suggestions",
                        },
                    },
                    {
                        name: "ModelExtractFromDocument",
                        path: Urls.MODEL_EXTRACT,
                        component: ModelView,
                        props: () => ({
                            view: "ModelExtractFromDocument",
                        }),
                        meta: {
                            title: "Model Extract From Document",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Extract From Document",
                        },
                    },
                    {
                        name: "ModelScoringLabels",
                        path: Urls.MODEL_LABELS,
                        props: () => ({
                            view: "ModelScoringLabels",
                        }),
                        component: ModelView,
                        meta: {
                            title: "Model Scoring Labels",
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Scoring Labels",
                        },
                    },
                ],
            },
            {
                name: "Choices",
                path: Urls.CHOICES,
                component: ParentRouteView,
                meta: {
                    title: () => decisionsModule.choiceLabelPlural,
                    requireModel: true,
                    breadCrumb: true,
                    label: () => decisionsModule.choiceLabelPlural,
                },
                children: [
                    {
                        name: "Choices",
                        path: `${Urls.CHOICES}/`,
                        component: ChoicesView,
                        meta: {
                            title: () => `${decisionsModule.choiceLabelPlural}`,
                            requireModel: true,
                            label: () => decisionsModule.choiceLabelPlural,
                        },
                    },
                    {
                        name: "ChoicesSearchView",
                        path: Urls.CHOICES_SEARCH,
                        component: ChoicesView,
                        meta: {
                            title: () => `${decisionsModule.choiceLabelPlural} Search`,
                            breadCrumb: true,
                            requireModel: true,
                            label: () => `Find Existing ${decisionsModule.choiceLabelPlural}`,
                        },
                    },
                    {
                        name: "ChoicesViewAI",
                        path: Urls.CHOICES_AI,
                        component: ChoicesView,
                        meta: {
                            title: () => `${decisionsModule.choiceLabelPlural} AI Suggestions`,
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "AI Suggestions",
                        },
                    },
                    {
                        name: "ChoicesTableDataView",
                        path: Urls.CHOICES_LOAD,
                        component: ChoicesView,
                        meta: {
                            title: () => `${decisionsModule.choiceLabelPlural} Table Data View`,
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Extract form Table",
                        },
                    },
                    {
                        name: "ChoicesExtractFromDocument",
                        path: Urls.CHOICES_EXTRACT,
                        component: ChoicesView,
                        meta: {
                            title: () => `${decisionsModule.choiceLabelPlural} Extract From Document`,
                            breadCrumb: true,
                            requireModel: true,
                            label: () => "Extract From Document",
                        },
                    },
                    {
                        name: "Choice",
                        path: Urls.CHOICE,
                        component: ChoiceEditor,
                        meta: {
                            title: () => decisionsModule.choiceLabelSingular,
                            requireModel: true,
                            breadCrumb: true,
                            label: () => "Editor",
                        },
                    },
                ],
            },
            {
                name: "Viewpoints",
                path: Urls.VIEWPOINTS,
                component: ViewpointsView,
                meta: {
                    title: "Viewpoints",
                    requireModel: true,
                    breadCrumb: true,
                    label: () => "Viewpoints",
                },
            },
            {
                name: "ScoringClass",
                path: Urls.SCORINGCLASS,
                component: ScoreClassTable,
                meta: {
                    title: "Scoring Class",
                    breadCrumb: true,
                    label: () => "Scoring Classes",
                },
            },
            {
                name: "Model Admin",
                path: Urls.MODEL_ADMIN,
                component: ModelView,
                meta: {
                    title: "Model Admin",
                },
                props: (route: any) => ({ isAdmin: true }),
            },
            // {
            //     name: "Units",
            //     path: Urls.UNITS,
            //     component: UnitView,
            //     meta: {
            //         title: "Units",
            //         breadCrumb: true,
            //         label: () => "Measurement Units",
            //     },
            // },
            {
                name: "Compare",
                path: Urls.COMPARE,
                component: CompareView,
                props: () => ({
                    page: "CompareView",
                }),
                meta: {
                    title: "Manual Scoring",
                    requireModel: true,
                    breadCrumb: true,
                    label: () => "Manual Scoring",
                    retainQuery: true,
                },
            },
            {
                name: "RankView",
                path: Urls.RANK,
                component: RankView,
                props: () => ({
                    page: "RankView",
                }),
                meta: {
                    title: "Rank",
                    requireModel: true,
                    breadCrumb: true,
                    label: () => "Rank",
                },
            },
            {
                name: "DecisionsView",
                path: Urls.DECISIONS,
                component: DecisionsView,
                meta: {
                    title: "Frameworks",
                },
            },
            {
                name: "WorkspacesView",
                path: Urls.WORKSPACES,
                component: WorkspacesView,
                props: { frameworkRedirect: true },
                meta: {
                    title: "Workspaces",
                },
            },
            {
                name: "UsersView",
                path: Urls.USERS,
                component: UsersView,
                meta: {
                    title: "Users",
                    breadCrumb: true,
                    label: () => "Users",
                },
            },
            {
                name: "AppsListEditorView",
                path: `${Urls.APPSLISTEDITOR}/:appId?`,
                component: AppManagerView,
                meta: {
                    title: "Apps Editor",
                    breadCrumb: true,
                    requireModel: true,
                    label: () => "Apps Editor",
                    useCustomPath: true,
                    customPath: Urls.APPSLISTEDITOR,
                },
                children: [
                    {
                        name: "TabEditorView",
                        path: `${Urls.TABEDITOR}/:appId?/:tabId?`,
                        component: TabEditorView,
                        meta: {
                            title: "App Editor",
                            breadCrumb: true,
                            requireModel: true,
                            breadCrumSlotOnly: true,
                        },
                    },
                ],
            },
            {
                name: "AppsListView",
                path: Urls.APPSLIST,
                component: AppManagerView,
                meta: {
                    title: "Apps Editor",
                    breadCrumb: false,
                    requireModel: true,
                    label: () => "Apps Editor",
                },
            },
            {
                name: "AccountView",
                path: Urls.ACCOUNT,
                component: AccountView,
                meta: {
                    title: "My Profile",
                    breadCrumb: false,
                    requireModel: false,
                    label: () => "My Profile",
                },
            },
        ],
    },
    {
        path: Urls.LOGIN,
        name: "Login",
        component: LoginView,
        children: [
            {
                name: "Signup",
                path: Urls.SIGNUP,
                component: SignupForm,
                meta: {
                    title: "Sign Up",
                    open: true,
                },
            },
            {
                name: "Verify",
                path: Urls.VERIFY,
                component: SignupForm,
                meta: {
                    title: "Verify your account",
                    open: true,
                },
                props: () => ({
                    enterEmail: true,
                }),
            },
            {
                name: "LoginForm",
                path: Urls.LOGIN,
                component: LoginForm,
                meta: {
                    title: "Login",
                    open: true,
                },
            },
            {
                name: "Reset",
                path: Urls.RESET,
                component: ResetForm,
                meta: {
                    title: "Password Reset",
                    open: true,
                },
            },
            {
                name: "AWSLogin",
                path: `${Urls.LOGIN}/aws-login`,
                component: AWSLogin,
                meta: {
                    open: true,
                },
            },
        ],
    },
    {
        path: `${Urls.INVITATION}/:workspaceId?`,
        name: "InvitationView",
        component: InvitationView,
        meta: {
            open: true,
            blockLoginRedirect: true,
            consistentUI: true,
        },
    },
    {
        path: `${Urls.APPS}/:appId?/:tabId?`,
        name: "AppView",
        component: AppView,
        meta: {
            title: "Apps",
            requireModel: true,
            blockLoginRedirect: true,
        },
    },
    {
        path: Urls.DECISION,
        name: "Decision",
        component: Decision,
        meta: {
            title: "Decisions",
        },
        props: (route) => ({
            queryWorkId: route.query.workId,
            queryDecId: route.query.decId,
        }),
    },
    {
        path: "*",
        name: "404",
        component: NotFound,
        meta: {
            title: "404",
        },
    },
    {
        name: "AccountView",
        path: Urls.ACCOUNT,
        component: AccountView,
        meta: {
            title: "My Profile",
            label: () => "My Profile",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
